import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { GeometryType } from '@core/model/application-api/layer.model';
import { LayerDynamicStyle, LayerDynamicStyleModel } from '@core/model/layer-dynamic-style';
import { LayerDynamicStyleCategories } from '@core/model/layer-dynamic-style-categories';
import { LayerDynamicStyleGradient } from '@core/model/layer-dynamic-style-gradient';
import { CustomLayerStyle, CustomStyle } from '@core/model/layer-style.model';
import { FillStyle } from '@core/model/styles/fill-style.model';
import { StrokeStyle } from '@core/model/styles/stroke-style.model';
import { FillPattern, StrokePattern, SymbolPattern, TextAlignment } from '@core/model/styles/style-config.model';
import { StyleRule, StyleRuleModel } from '@core/model/styles/style-rule.model';
import { SymbolStyle } from '@core/model/styles/symbol-style.model';
import { TextStyle } from '@core/model/styles/text-style.model';
import { colorPickerValidator } from './color-picker-validator';
import { DynamicStyleType } from './style-form-config.model';

// Base form

interface PointForm {
  symbol: FormControl<SymbolPattern | null>;
  symbolSize: FormControl<number | null>;
  symbolFillColor: FormControl<string | null>;
  symbolStrokeColor: FormControl<string | null>;
  symbolStrokeWidth: FormControl<number | null>;
}

interface LineForm {
  strokePattern: FormControl<StrokePattern | null>;
  strokeColor: FormControl<string | null>;
  strokeWidth: FormControl<number | null>;
}

interface PolygonForm extends LineForm {
  fillPattern: FormControl<FillPattern | null>;
  fillColor: FormControl<string | null>;
}

interface TextForm {
  text: FormControl<string | null>;
  fontSize: FormControl<number | null>;
  alignment: FormControl<TextAlignment | null>;
  offsetX: FormControl<number | null>;
  offsetY: FormControl<number | null>;
  textBackGroundColor: FormControl<string | null>;
  textStrokeColor: FormControl<string | null>;
  textFillColor: FormControl<string | null>;
  textMargin: FormControl<number | null>;
}

export interface BaseStyleForm {
  point?: FormGroup<PointForm>;
  line?: FormGroup<LineForm>;
  polygon?: FormGroup<PolygonForm>;
  text?: FormGroup<TextForm>;
}

export const buildPointForm = () => {
  return new FormGroup<PointForm>({
    symbol: new FormControl<SymbolPattern | null>(null, Validators.required),
    symbolSize: new FormControl<number | null>(null, Validators.min(0)),
    symbolFillColor: new FormControl<string | null>(null, colorPickerValidator),
    symbolStrokeColor: new FormControl<string | null>(null, colorPickerValidator),
    symbolStrokeWidth: new FormControl<number | null>(null, Validators.min(0)),
  });
};

export const buildPolygonForm = () => {
  return new FormGroup<PolygonForm>({
    fillPattern: new FormControl<FillPattern | null>(null, Validators.required),
    fillColor: new FormControl<string | null>(null, colorPickerValidator),
    strokePattern: new FormControl<StrokePattern | null>(null, Validators.required),
    strokeColor: new FormControl<string | null>(null, colorPickerValidator),
    strokeWidth: new FormControl<number | null>(null, Validators.min(0)),
  });
};

export const buildLineForm = () => {
  return new FormGroup<LineForm>({
    strokePattern: new FormControl<StrokePattern | null>(null, Validators.required),
    strokeColor: new FormControl<string | null>(null, colorPickerValidator),
    strokeWidth: new FormControl<number | null>(null, Validators.min(0)),
  });
};

export const buildTextForm = () => {
  return new FormGroup<TextForm>({
    text: new FormControl<string | null>(null),
    fontSize: new FormControl<number | null>(null, Validators.min(0)),
    alignment: new FormControl<TextAlignment | null>(null, Validators.required),
    offsetX: new FormControl<number | null>(null),
    offsetY: new FormControl<number | null>(null),
    textBackGroundColor: new FormControl<string | null>(null),
    textStrokeColor: new FormControl<string | null>(null, colorPickerValidator),
    textFillColor: new FormControl<string | null>(null, colorPickerValidator),
    textMargin: new FormControl<number | null>(null, Validators.min(0)),
  });
};

export const buildBaseForm = (geometryType?: GeometryType): FormGroup<BaseStyleForm> => {
  const form = new FormGroup<BaseStyleForm>({ text: buildTextForm() });
  switch (geometryType) {
    case GeometryType.POINT:
      form.addControl('point', buildPointForm());
      break;
    case GeometryType.LINE:
      form.addControl('line', buildLineForm());
      break;
    case GeometryType.POLYGON:
      form.addControl('polygon', buildPolygonForm());
      break;
    default:
      form.addControl('point', buildPointForm());
      form.addControl('polygon', buildPolygonForm());
  }

  return form;
};

// Dynamic form

export interface DynamicStyleForm {
  attribute: FormControl<string | null>;
  palette: FormControl<string | null>;

  // Gradient mode
  minValue?: FormControl<number | null>;
  maxValue?: FormControl<number | null>;
  nbRanges?: FormControl<number | null>;
  useFirstRangeIfLesser?: FormControl<boolean | null>;
  useLastRangeIfGreater?: FormControl<boolean | null>;
}

export interface RuleForm {
  label: FormControl<string | null>;
  minScale: FormControl<number | null>;
  maxScale: FormControl<number | null>;
  cqlValue: FormControl<string | null>;
  style: FormGroup<BaseStyleForm>;
}

export const buildRuleForm = (geometryType?: GeometryType) => {
  return new FormGroup<RuleForm>({
    label: new FormControl<string | null>(null),
    minScale: new FormControl<number | null>(null, Validators.min(0)),
    maxScale: new FormControl<number | null>(null, Validators.min(0)),
    cqlValue: new FormControl<string | null>(null),
    style: buildBaseForm(geometryType),
  });
};

export const prepareBaseStyle = (style: CustomLayerStyle, palette?: string[], length?: number) => {
  if (palette && length != undefined) {
    style.applyCategorieColor(palette[length % palette.length]);
  }
  return {
    point: { ...style.symbolStyle.toModel() },
    line: { ...style.strokeStyle.toModel() },
    polygon: { ...style.strokeStyle.toModel(), ...style.fillStyle.toModel() },
    text: { ...style.textStyle.toModel() },
  };
};

export const prepareRule = (rule: StyleRule) => {
  return {
    label: rule.label,
    minScale: rule.minScaleDenom,
    maxScale: rule.maxScaleDenom,
    cqlValue: rule.cqlValue,
    style: {
      point: { ...rule.style.symbolStyle.toModel() },
      line: { ...rule.style.strokeStyle.toModel() },
      polygon: { ...rule.style.strokeStyle.toModel(), ...rule.style.fillStyle.toModel() },
      text: { ...rule.style.textStyle.toModel() },
    },
  };
};

export const formToStyle = (styleForm: FormGroup<BaseStyleForm>, baseStyle?: CustomStyle): CustomLayerStyle => {
  const symbolStyle = new SymbolStyle(styleForm.value.point);
  const fillStyle = new FillStyle(styleForm.value.polygon);
  const strokeStyle = new StrokeStyle(styleForm.value.polygon);
  const textStyle = new TextStyle(styleForm.value.text);
  return new CustomLayerStyle({
    baseStyle: baseStyle,
    pointGeometry: false,
    symbolStyle: symbolStyle,
    fillStyle: fillStyle,
    strokeStyle: strokeStyle,
    textStyle: textStyle,
  });
};

export const formToDynamicStyle = (
  styleForm: FormGroup<DynamicStyleForm>,
  rules: FormArray<FormGroup<RuleForm>>,
  type: DynamicStyleType,
  baseStyle?: LayerDynamicStyleModel
): LayerDynamicStyle | undefined => {
  if (type === 'categories') {
    return new LayerDynamicStyleCategories({
      baseStyle: baseStyle,
      attribute: styleForm.value.attribute ?? '',
      palette: styleForm.value.palette ?? 'no-colors',
      categories: formToRules(rules),
    });
  } else if (type === 'gradient') {
    return new LayerDynamicStyleGradient({
      baseStyle: baseStyle,
      attribute: styleForm.value.attribute ?? '',
      palette: styleForm.value.palette ?? 'no-colors',
      categories: formToRules(rules),
      minAttributeValue: styleForm.value.minValue ?? 0,
      maxAttributeValue: styleForm.value.maxValue ?? 100,
      useFirstRangeIfLesser: styleForm.value.useFirstRangeIfLesser ?? false,
      useLastRangeIfGreater: styleForm.value.useLastRangeIfGreater ?? false,
    });
  }
  return;
};

const formToRules = (ruleForm: FormArray<FormGroup<RuleForm>>): StyleRuleModel[] => {
  const rules: StyleRuleModel[] = [];
  ruleForm.value.forEach((rule, index) => {
    const style = formToStyle(ruleForm.at(index).controls.style);
    const newRule: StyleRuleModel = {
      label: rule.label ?? '',
      cqlValue: rule.cqlValue ?? undefined,
      minScaleDenom: rule.minScale ?? undefined,
      maxScaleDenom: rule.maxScale ?? undefined,
      style: style.toModel(),
    };
    rules.push(newRule);
  });
  return rules;
};
