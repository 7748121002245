<smv-form-field-wrapper [formControl]="heatmapWeightControl" controlType="select" [selectOptions]="attributes" required>
  <span mat-label i18n="Layer heatmap">Pondération</span>
</smv-form-field-wrapper>

<smv-form-field-wrapper [formControl]="heatmapRadiusControl" type="number">
  <span mat-label i18n="Layer heatmap">Rayon (en pixels)</span>
</smv-form-field-wrapper>

<smv-form-field-wrapper [formControl]="heatmapBlurControl" type="number">
  <span mat-label i18n="Layer heatmap">Flou (en pixels)</span>
</smv-form-field-wrapper>

<smv-form-field-wrapper [formControl]="heatmapGradientControl" controlType="select" [selectOptions]="palettes">
  <span mat-label i18n="Form field|Palette selection">Palette de couleurs</span>
</smv-form-field-wrapper>

<smv-form-field-wrapper [formControl]="heatmapZoomControl" hasHint type="number">
  <span mat-label i18n="Layer heatmap">Zoom</span>
  <span mat-hint i18n="Hint|Fixed Zoom level"
    >Niveau de zoom si le bloquage du zoom est activé (fonctionnalité spécifique, laisser vide pour ignorer)</span
  >
</smv-form-field-wrapper>
