import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { FillPattern, StrokePattern, SymbolPattern, TextAlignment } from '@core/model/styles/style-config.model';

const FILL_PATTERNS: readonly FillPattern[] = Object.freeze([
  'backslash',
  'circle',
  'cross',
  'dot',
  'horline',
  'plus',
  'simple',
  'slash',
  'square',
  'vertline',
]);

const STROKE_PATTERNS: readonly StrokePattern[] = Object.freeze([
  'backslash',
  'circle',
  'cross',
  'plus',
  'simple',
  'slash',
  'square',
  'vertline',
]);

const SYMBOLS: readonly SymbolPattern[] = Object.freeze(['circle', 'cross', 'square', 'star', 'triangle']);

const ALIGNMENTS: readonly TextAlignment[] = Object.freeze(['center', 'end', 'left', 'right', 'start']);

export const toOptions = (patterns: readonly string[]) =>
  patterns.map((pattern) => ({
    label: pattern,
    value: pattern,
  }));

export type DynamicStyleType = 'aucun' | 'categories' | 'gradient';

export const FILL_PATTERN_OPTIONS: DropdownOption<string>[] = toOptions(FILL_PATTERNS);
export const STROKE_PATTERN_OPTIONS: DropdownOption<string>[] = toOptions(STROKE_PATTERNS);
export const SYMBOL_OPTIONS: DropdownOption<string>[] = toOptions(SYMBOLS);
export const ALIGNMENT_OPTIONS: DropdownOption<string>[] = toOptions(ALIGNMENTS);
export const DYNAMIC_STYLE_TYPE_OPTIONS: DropdownOption<DynamicStyleType>[] = [
  { label: $localize`:Layer dynamic style:Aucun`, value: 'aucun' },
  { label: $localize`:Layer dynamic style:Par catégorie`, value: 'categories' },
  { label: $localize`:Layer dynamic style:Dégradé`, value: 'gradient' },
];
