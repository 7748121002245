import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { DialogService } from '@components/dialog/dialog.service';
import { TableComponent } from '@components/table/table.component';
import {
  ActionType,
  CellType,
  Column,
  DefaultType,
  GeneralActions,
  SelectionAction,
} from '@components/table/table.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { NotificationService } from '@core/services/notification.service';
import { XlsxSheet, exportToXlsx } from '@core/utils/xlsx.utils';
import { InterraScanService } from '../interra-scan.service';
import { Farmer } from '../model/farmer.model';
import { InterraScanFarmerFormComponent } from './farmer-form/farmer-form.component';

@Component({
  standalone: true,
  selector: 'smv-interra-scan-farmer-stats',
  templateUrl: './farmer-stats.component.html',
  styleUrls: ['./farmer-stats.component.scss'],
  imports: [CommonModule, MatDialogModule, MatCheckboxModule, MatButtonModule, MatDividerModule, TableComponent],
})
export class FarmerStatsComponent implements OnInit {
  @Input() title = $localize`:InterraScan|Farmer management:Gestion des agriculteurs`;
  @Input() appId!: number;
  public farmersList: Farmer[] = [];
  public selection: Farmer[] = [];
  public actionsConfig: GeneralActions<Farmer> = {};
  public columns: Column<Farmer, DefaultType<string>>[] = [];
  public paging: PagingInfo = new PagingInfo('interra-scan-farmer-list');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appId: number },
    private notificationService: NotificationService,
    private interraScanService: InterraScanService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.appId = data.appId;
  }

  ngOnInit(): void {
    this.actionsConfig = {
      checkboxAllSelected: true,
      addDisabled: false,
      addIcon: 'person_add',
      addTooltip: $localize`:InterraScan|Add farmer:Ajouter un agriculteur`,
      searchDisabled: false,
      searchPlaceholder: $localize`:InterraScan|Farmer management:Rechercher par nom/exploitation/client`,
      refreshDisabled: true,
    };
    this.columns = [
      {
        field: 'firstName',
        label: $localize`:Label|First name:Prénom`,
        type: CellType.String,
        cellData: 'firstName',
      },
      {
        field: 'lastName',
        label: $localize`:Label|Last name:Nom`,
        type: CellType.String,
        cellData: 'lastName',
      },
      {
        field: 'farmName',
        label: $localize`:Label|Farm name:Exploitation`,
        type: CellType.String,
        cellData: 'farmName',
      },
      {
        field: 'siret',
        label: $localize`:Label|Farm siret:Siret`,
        type: CellType.String,
        cellData: 'siret',
      },
      {
        field: 'email',
        label: $localize`:Label|Email:Email`,
        type: CellType.String,
        cellData: 'email',
      },
      {
        field: 'phone',
        label: $localize`:Label|Phone:Téléphone`,
        type: CellType.String,
        cellData: 'phone',
      },
      {
        field: 'customer',
        label: $localize`Client`,
        type: CellType.StringBis,
        cellData: 'customer',
        cellDataBis: 'name',
        allowCellEmpty: true,
      },
      {
        field: 'modificationDate',
        label: $localize`Date modification`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'modificationDate',
      },
      {
        field: 'modificator',
        label: $localize`Modificateur`,
        type: CellType.StringBis,
        cellData: 'modificator',
        cellDataBis: 'email',
        allowCellEmpty: true,
      },
      {
        field: 'actions',
        label: '',
        type: CellType.Action,
        cellData: '',
        actions: [
          {
            type: ActionType.Menu,
            tooltip: $localize`:Access tooltip:Plus d'actions`,
            identifier: 'id',
            menu: [
              {
                type: ActionType.Modify,
                identifier: 'id',
                label: $localize`:Button:Modifier`,
                tooltip: $localize`:InterraScan|Modify tooltip:Modifier l'agriculteur`,
              },
              {
                type: ActionType.Delete,
                identifier: 'email',
                label: $localize`:Button:Supprimer`,
                tooltip: $localize`:InterraScan|Delete tooltip:Supprimer l'agriculteur`,
              },
            ],
          },
        ],
      },
    ];

    this.findFarmers();
  }

  findFarmers() {
    this.interraScanService.retrieveFarmers(this.appId, this.paging).subscribe({
      next: (result) => {
        if (result.totalElements > 0) {
          this.farmersList = result.content;
        } else {
          this.farmersList = [];
        }
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }

  exportToCalc(farmers?: Farmer[]) {
    if (!farmers) {
      this.interraScanService
        .retrieveAllFarmers(this.appId, this.paging)
        .subscribe((allFarmers) => this.buildExportCalc(allFarmers));
    } else {
      this.buildExportCalc(farmers);
    }
  }

  private buildExportCalc(farmers: Farmer[]) {
    const farmersSheet: XlsxSheet = {
      name: $localize`:InterraScan|Export Sheet:Récapitulatif des agriculteurs`,
      headerRow: this.columns.map((column) => column.label),
      dataRows: this.getExportRows(farmers),
    };

    exportToXlsx($localize`:InterraScan|Export file:export_agriculteurs`, [farmersSheet]);
  }

  private getExportRows(farmers: Farmer[]): string[][] {
    const result: string[][] = [];
    farmers.forEach((e) => {
      result.push([
        e.firstName ?? '',
        e.lastName ?? '',
        e.farmName ?? '',
        e.siret ?? '',
        e.email,
        e.phone ?? '',
        e.customer.name,
        String(e.modificationDate ?? ''),
        e.modificator.email ?? '',
      ]);
    });
    return result;
  }

  openModifyDialog(farmerId?: string) {
    let farmer: Farmer | undefined = undefined;
    if (farmerId) {
      farmer = this.farmersList.find((f) => f.id === farmerId);
    }
    this.dialog
      .open(InterraScanFarmerFormComponent, { data: farmer })
      .afterClosed()
      .subscribe((result: false | Farmer) => {
        if (result) {
          if (farmerId) {
            const modifiedFarmer: Farmer = { ...farmer, ...result };
            this.interraScanService.modifyFarmer(modifiedFarmer).subscribe({
              next: () => {
                this.notificationService.success(
                  $localize`:InterraScan|Farmer update successful:La modification de l'agriculteur a bien été sauvegardée.`
                );
                this.findFarmers();
              },
              error: this.handleError.bind(this),
            });
          } else {
            this.interraScanService.createFarmer(result).subscribe({
              next: () => {
                this.notificationService.success(
                  $localize`:InterraScan|Farmer creation successful:La création de l'agriculteur a bien été sauvegardée.`
                );
                this.findFarmers();
              },
              error: this.handleError.bind(this),
            });
          }
        }
      });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == HttpStatusCode.ExpectationFailed) {
      this.notificationService.error(
        $localize`:InterraScan|Farmer slfc sync not complete:Enregistrement effectué, mais erreur de synchronisation avec Salesforce. Veuillez notifier l'administrateur avec le code.`
      );
      this.findFarmers();
    } else if (error.status == HttpStatusCode.BadRequest) {
      this.notificationService.error(
        $localize`:InterraScan|Farmer not complete:Une erreur est survenue, des données obligatoires de l'agriculteur sont manquantes. Veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`
      );
    } else if (error.status == HttpStatusCode.Conflict) {
      this.notificationService.error(
        $localize`:InterraScan|Farmer already exists:L'agriculteur existe déjà pour le client renseigné.`
      );
    } else if (error.status == HttpStatusCode.Forbidden) {
      this.notificationService.error(
        $localize`:InterraScan|Farmer access denied:Vous n'avez les autorisations pour modifier l'agriculteur séléctioné.`
      );
    } else {
      this.notificationService.unknownServerError();
    }
  }

  openConfirmDeleteDialog(deleteData: SelectionAction<string>) {
    if (deleteData.identifiers.length > 0) {
      this.dialogService.openFarmerDeleteConfirm(deleteData.identifiers[0]).subscribe((result: boolean) => {
        if (result) {
          this.interraScanService.deleteFarmer(deleteData.ids[0]).subscribe({
            next: () => {
              this.notificationService.success(
                $localize`:InterraScan|Farmer deletion successful:Les agriculteurs ont été correctement supprimés.`
              );
              this.findFarmers();
            },
            error: (error: HttpErrorResponse) => {
              if (error.status == 400) {
                this.notificationService.error(
                  $localize`:InterraScan|Farmer not found:Une erreur est survenue, l'agriculteur n'a pas été trouvé. Veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`
                );
              } else {
                this.notificationService.unknownServerError();
              }
            },
          });
        }
      });
    }
  }
}
