import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { Loader, LoadingComponent } from '@components/loading/loading.component';
import { TableComponent } from '@components/table/table.component';
import { CellType, Column, DefaultType, GeneralActions } from '@components/table/table.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { NotificationService } from '@core/services/notification.service';
import { XlsxSheet, exportToXlsx } from '@core/utils/xlsx.utils';
import { TrapStatement } from '@widgets/vigie-virose/model/trap.model';
import { VigieViroseService } from '@widgets/vigie-virose/services/vigie-virose.service';

@Component({
  standalone: true,
  selector: 'smv-vigievirose-user-datum-stats',
  templateUrl: './user-datum-stats.component.html',
  styleUrls: ['./user-datum-stats.component.scss'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDividerModule,
    TableComponent,
    LoadingComponent,
  ],
})
export class UserDatumStatsComponent implements OnInit {
  @Input() title = $localize`Récapitulatif des relevés`;
  @Input() appId!: number;
  public datumList: TrapStatement[] = [];
  public selection: TrapStatement[] = [];
  public actionsConfig: GeneralActions<TrapStatement> = {};
  public columns: Column<TrapStatement, DefaultType<number>>[] = [];
  public paging: PagingInfo = new PagingInfo('vv-datum-list');
  public loading: Loader = {
    condition: false,
    text: $localize`:Loader|Getting:Chargement des données, veuillez patienter`,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appId: number },
    private notificationService: NotificationService,
    private vigieViroseService: VigieViroseService
  ) {
    this.appId = data.appId;
  }

  ngOnInit(): void {
    this.actionsConfig = {
      checkboxAllSelected: true,
      searchDisabled: false,
      searchPlaceholder: $localize`Rechercher par client ou par code de piège`,
      refreshDisabled: true,
      defaultSort: { active: 'deliveryDate', direction: 'desc' },
    };
    this.columns = [
      {
        field: 'trap.code',
        label: $localize`Code`,
        type: CellType.StringBis,
        cellData: 'trap',
        cellDataBis: 'code',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'trap.customer.name',
        label: $localize`Client`,
        type: CellType.StringTer,
        cellData: 'trap',
        cellDataBis: 'customer',
        cellDataTer: 'name',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'etat',
        label: $localize`État`,
        type: CellType.String,
        cellData: 'etat',
        sort: true,
      },
      {
        field: 'deliveryDate',
        label: $localize`Date réception`,
        type: CellType.String,
        cellData: 'deliveryDate',
        sort: true,
      },
      {
        type: CellType.String,
        field: 'samplingDate',
        label: $localize`Date relevé`,
        cellData: 'samplingDate',
        sort: true,
      },
      {
        type: CellType.String,
        field: 'puceron',
        label: $localize`Puceron`,
        cellData: 'puceron',
        sort: true,
      },
      {
        type: CellType.String,
        field: 'pav',
        label: $localize`Pav`,
        cellData: 'pavHuman',
      },
      {
        type: CellType.String,
        field: 'mav',
        label: $localize`Mav`,
        cellData: 'mavHuman',
      },
      {
        type: CellType.String,
        field: 'rpv',
        label: $localize`Rpv`,
        cellData: 'rpvHuman',
      },
      {
        type: CellType.String,
        field: 'bwyv',
        label: $localize`Bwyv`,
        cellData: 'bwyvHuman',
      },
      {
        type: CellType.String,
        field: 'camv',
        label: $localize`Camv`,
        cellData: 'camvHuman',
      },
      {
        type: CellType.String,
        field: 'tumv',
        label: $localize`Tumv`,
        cellData: 'tumvHuman',
      },
      {
        type: CellType.String,
        field: 'cicadelle',
        label: $localize`Cicadelle`,
        cellData: 'cicadelle',
        sort: true,
      },
      {
        type: CellType.String,
        field: 'wdv',
        label: $localize`Wdv`,
        cellData: 'wdvHuman',
      },
      {
        type: CellType.String,
        field: 'altise',
        label: $localize`Altise`,
        cellData: 'altise',
        sort: true,
      },
      {
        type: CellType.String,
        field: 'tenthrede',
        label: $localize`Tenthèdre`,
        cellData: 'tenthedre',
        sort: true,
      },
    ];

    this.findDatums();
  }

  generateHumanReadable(results: TrapStatement[]) {
    results.forEach((element) => {
      element.bwyvHuman = '+'.repeat(element.bwyv);
      element.camvHuman = '+'.repeat(element.camv);
      element.tumvHuman = '+'.repeat(element.tumv);
      element.wdvHuman = '+'.repeat(element.wdv);
      element.pavHuman = '+'.repeat(element.pav);
      element.mavHuman = '+'.repeat(element.mav);
      element.rpvHuman = '+'.repeat(element.rpv);
    });
    return results;
  }

  findDatums() {
    this.loading.condition = true;
    this.vigieViroseService.retrieveTrapStatements(this.appId, this.paging).subscribe({
      next: (result) => {
        result.content = this.generateHumanReadable(result.content);
        if (result.totalElements > 0) {
          this.datumList = result.content;
        } else {
          this.datumList = [];
        }
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
      complete: () => (this.loading.condition = false),
    });
  }

  exportToCalc(datums?: TrapStatement[]) {
    if (!datums) {
      this.vigieViroseService
        .retrieveAllTrapStatements(this.appId, this.paging)
        .subscribe((datumList) => this.buildExportCalc(this.generateHumanReadable(datumList)));
    } else {
      this.buildExportCalc(datums);
    }
  }

  private buildExportCalc(datums: TrapStatement[]) {
    const trapsSheet: XlsxSheet = {
      name: $localize`Récapitulatif des relevés`,
      headerRow: this.columns.map((column) => column.label),
      dataRows: this.getExportRows(datums),
    };

    exportToXlsx($localize`export_relevés_pièges`, [trapsSheet]);
  }

  private getExportRows(datums: TrapStatement[]): string[][] {
    const result: string[][] = [];
    datums.forEach((e) => {
      result.push([
        e.trap?.code,
        e.trap?.customer?.name ?? '',
        e.etat,
        String(e.deliveryDate),
        String(e.samplingDate),
        String(e.puceron ?? ''),
        e.pavHuman,
        e.mavHuman,
        e.rpvHuman,
        e.bwyvHuman,
        e.camvHuman,
        e.tumvHuman,
        String(e.cicadelle ?? ''),
        e.wdvHuman,
        String(e.altise ?? ''),
        String(e.tenthrede ?? ''),
      ]);
    });
    return result;
  }
}
