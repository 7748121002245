import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { MapService } from '@core/services/map.service';
import { SelectionService } from '@core/services/selection.service';
import { Subscription } from 'rxjs';
import { StatisticConfig } from '../components/statistic/statistic.model';
import { StatisticService } from '../components/statistic/statistic.service';
import { FilterConfig } from './model/context-item-config.model';
import { VigieViroseService } from './services/vigie-virose.service';
import { VigieViroseAllTrapsConfig, VigieViroseLayerConfig } from './vigie-virose-config';
import { VigieViroseDisplayMode } from './vigie-virose-display-mode/vigie-virose-display-mode.component';

@Component({
  selector: 'smv-vigie-virose',
  templateUrl: './vigie-virose.component.html',
  styleUrls: ['./vigie-virose.component.scss', '../../client-app-panel.scss'],
})
export class VigieViroseComponent implements OnDestroy {
  public displayMode = VigieViroseDisplayMode.MODE_POINTS;
  public filterType = 'FILTER_OBSERVATION';
  public isSheetDisplayed = false;

  public allTrapLayerCode = new VigieViroseAllTrapsConfig().getLayerCode();

  private readonly layerConfig = new VigieViroseLayerConfig();
  private readonly subscriptions = new Subscription();

  @Input() applicationId!: number;
  @Input() panelHide = true;
  @Output() displayed = new EventEmitter();

  constructor(
    private readonly vigieViroseService: VigieViroseService,
    private readonly statisticService: StatisticService,
    private readonly selectionService: SelectionService,
    private readonly mapService: MapService
  ) {
    this.subscriptions.add(
      this.statisticService.statisticState
        .getStream()
        .subscribe((config: StatisticConfig) => (this.isSheetDisplayed = config.display))
    );
    this.subscriptions.add(
      this.vigieViroseService.filterState.getStream().subscribe((filter) => this.updateLayerDisplayAndRender(filter))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.mapService.setZoomActivated(true);
  }

  updateLayerDisplayAndRender(filter: FilterConfig) {
    const layersCodeConfig = this.layerConfig.retrieveLayerCodeConfig(filter);
    let zoomLevel = 0;
    for (const layerCode of Object.keys(layersCodeConfig)) {
      const layer = this.mapService.getLayerByCode(layerCode);
      if (layer) {
        layer.setLayerVisibility(layersCodeConfig[layerCode]);
        if (layersCodeConfig[layerCode]) {
          zoomLevel = layer.config.heatmap.heatZoom ?? 0;
        }
      }
    }

    if (zoomLevel > 0) {
      this.mapService.setZoomActivated(false);
      this.mapService.setZoom(zoomLevel);
    } else {
      this.mapService.setZoomActivated(true);
    }
    this.mapService.zoomActive.setValue(zoomLevel <= 0);

    this.selectionService.activeWidgetStatisticSelection(filter.displayFilter === 'FILTER_OBSERVATION');
  }
}
