import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedResult } from '@core/model/paginated-result.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { Palette } from '@core/model/styles/palette.model';
import { Observable } from 'rxjs';
import { CommonService, ListWrapper } from './common.service';

@Injectable({ providedIn: 'root' })
export class PaletteService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/palettes';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getPalettes(): Observable<Palette[]> {
    return this.pipeExtractList(this.http.get<ListWrapper<Palette>>(this.baseUrl));
  }

  override sendListQuery<T>(pagingInfo?: PagingInfo, url?: string, modifyUrl = false): Observable<PaginatedResult<T>> {
    return super.sendListQuery(pagingInfo, url ?? this.baseUrl + '/page', modifyUrl);
  }

  createPalette(palette: Palette): Observable<void> {
    return this.http.post<void>(this.baseUrl, palette);
  }

  updatePalette(palette: Palette): Observable<void> {
    return this.http.put<void>(this.baseUrl + `/${palette.id}`, palette);
  }

  deletePalette(paletteId: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/${paletteId}`);
  }
}
