import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { DropdownOption } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { LayerProperty } from '@core/model/application-api/layer.model';
import { PaletteService } from '@core/services/palette.service';
import { Indexable } from '@core/utils/general.utils';
import { DataLayerConfig } from '@feature/client-app/data-layer-config.model';

export interface HeatMapUpdate {
  heatWeightProperty: string;
  heatZoom?: number;
  heatBlur: number;
  heatRadius: number;
  heatGradient?: string[];
  heatGradientName?: string;
}

@Component({
  selector: 'smv-layer-heatmap-tab',
  templateUrl: './layer-heatmap-tab.component.html',
  styleUrls: ['./layer-heatmap-tab.component.scss'],
})
export class LayerHeatMapTabComponent implements OnInit, OnChanges {
  @Input() layer!: DataLayerConfig;
  @Input() properties?: LayerProperty[];
  @Input() readonly = false;

  @Output() heatmapUpdate = new EventEmitter<HeatMapUpdate | undefined>();

  private heatmapArrayForm?: FormArray;

  public heatmapWeightControl = new FormControl<string | null>(null, Validators.required);
  public heatmapZoomControl = new FormControl<number | null>(null, [Validators.min(0)]);
  public heatmapRadiusControl = new FormControl<number>(8, [Validators.min(0), Validators.required]);
  public heatmapBlurControl = new FormControl<number>(15, [Validators.min(0), Validators.required]);
  public heatmapGradientControl = new FormControl<string | null>(null);

  public attributes: DropdownOption<string>[] = [];
  public palettes: DropdownOption<string>[] = [];
  private palettesDefinition: Indexable<string[]> = {};

  constructor(private readonly paletteService: PaletteService) {
    this.paletteService.getPalettes().subscribe({
      next: (palettes) => {
        this.palettes = palettes
          .sort((p1, p2) => p1.name.localeCompare(p2.name))
          .map((palette) => {
            this.palettesDefinition[palette.name] = palette.colors;
            return {
              label: palette.name,
              value: palette.name,
            };
          });
      },
    });
  }

  ngOnInit() {
    this.heatmapArrayForm = new FormArray([
      this.heatmapWeightControl,
      this.heatmapZoomControl,
      this.heatmapRadiusControl,
      this.heatmapBlurControl,
      this.heatmapGradientControl,
    ]);

    this.heatmapArrayForm.valueChanges.subscribe(() => {
      if (this.heatmapArrayForm?.valid) {
        this.heatmapUpdate.emit({
          heatBlur: this.heatmapBlurControl.value ?? 15,
          heatZoom: this.heatmapZoomControl.value ?? undefined,
          heatRadius: this.heatmapRadiusControl.value ?? 8,
          heatWeightProperty: this.heatmapWeightControl.value ?? '',
          heatGradient: this.heatmapGradientControl.value
            ? this.palettesDefinition[this.heatmapGradientControl.value]
            : undefined,
          heatGradientName: this.heatmapGradientControl.value ?? undefined,
        });
      } else {
        this.heatmapUpdate.emit();
      }
    });

    this.heatmapWeightControl.patchValue(this.layer.config.heatmap.heatWeightProperty ?? null);
    this.heatmapZoomControl.patchValue(this.layer.config.heatmap.heatZoom ?? null);
    this.heatmapRadiusControl.patchValue(this.layer.config.heatmap.heatRadius ?? null);
    this.heatmapBlurControl.patchValue(this.layer.config.heatmap.heatBlur ?? null);
    this.heatmapGradientControl.patchValue(this.layer.config.heatmap.heatGradientName ?? null);
  }

  ngOnChanges() {
    if (this.properties) {
      this.attributes = this.properties.map((property) => {
        return { label: property.name, value: property.name };
      });
    }
  }
}
