<lib-ol-gmap [configuration]="mapConfig" (ready)="onMapReady($event)" #olgmap>
  <lib-ol-gmap-toolbar top-left-toolbar>
    <lib-ol-gmap-zoom-buttons *ngIf="isZoomActivated$ | async" />
    <lib-ol-gmap-geolocation-button #geolocation />
    <div class="smv-btn-tools-group">
      <lib-ol-gmap-measure-button
        *ngIf="widgetsVisibility?.[WidgetType.MEASURE_LENGTH] && widgetsLocalVisibility?.[WidgetType.MEASURE_LENGTH]"
        [type]="MeasureType.LENGTH"
        [beginMessage]="measureMessage.begin"
        [continueMessage]="measureMessage.continueLength"
        [(active)]="activeMeasure.length"
        (activeChange)="onMeasureActiveChanged(MeasureType.LENGTH)"
        [class]="{ 'smv-btn-active': activeMeasure.length }"
        matTooltip="Mesure de distance"
        matTooltipPosition="right"
        i18n-matTooltip
      />
      <lib-ol-gmap-measure-button
        *ngIf="widgetsVisibility?.[WidgetType.MEASURE_AREA] && widgetsLocalVisibility?.[WidgetType.MEASURE_AREA]"
        [type]="MeasureType.AREA"
        [beginMessage]="measureMessage.begin"
        [continueMessage]="measureMessage.continueArea"
        [(active)]="activeMeasure.area"
        (activeChange)="onMeasureActiveChanged(MeasureType.AREA)"
        [class]="{ 'smv-btn-active': activeMeasure.area }"
        matTooltip="Mesure de surface"
        matTooltipPosition="right"
        i18n-matTooltip
      />
    </div>
    <smv-geometry-edition
      *ngIf="layerToEdit && (canUserEditLayer || canUserModifyLayer)"
      [layer]="layerToEdit"
      [canOnlyModify]="!canUserEditLayer"
    />
  </lib-ol-gmap-toolbar>
  <lib-ol-gmap-toolbar bottom-left-toolbar *ngIf="mapReady">
    <lib-ol-gmap-cursor-position
      *ngIf="widgetsVisibility?.[WidgetType.COORDINATES] && widgetsLocalVisibility?.[WidgetType.COORDINATES]"
      [projection]="position.projection"
      [template]="position.template"
      [digits]="position.digits"
    />
    <lib-ol-gmap-scale *ngIf="widgetsVisibility?.[WidgetType.SCALE] && widgetsLocalVisibility?.[WidgetType.SCALE]" />
  </lib-ol-gmap-toolbar>
  <lib-ol-gmap-toolbar top-right-toolbar *ngIf="panelTogglesVisible">
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.LAYERS_MANAGER] && widgetsLocalVisibility?.[WidgetType.LAYERS_MANAGER]"
      icon="layers"
      (click)="togglePanel('dataLayers')"
      matTooltip="Couches de données"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="isFeatureSelected"
      icon="info_outline"
      (click)="togglePanel('featureDetails')"
      matTooltip="Informations"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.LEGEND] && widgetsLocalVisibility?.[WidgetType.LEGEND] && hasLegendAvailable"
      icon="format_list_bulleted"
      (click)="togglePanel('legend')"
      matTooltip="Légende"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.VIGIEVIROSE] && widgetsLocalVisibility?.[WidgetType.VIGIEVIROSE]"
      src="assets/widgets/vigieVirose/Vigie_Virose_icon.png"
      (click)="togglePanel('widgetVigieVirose')"
      matTooltip="VigieVirose"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.VIGIEVIROSE_ADMIN] && widgetsLocalVisibility?.[WidgetType.VIGIEVIROSE_ADMIN]"
      src="assets/widgets/vigieVirose/Vigie_Virose_icon.png"
      (click)="togglePanel('widgetVigieViroseAdmin')"
      matTooltip="VigieVirose Admin"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.ICARE] && widgetsLocalVisibility?.[WidgetType.ICARE]"
      src="assets/widgets/icare/eudemis.png"
      (click)="togglePanel('widgetIcare')"
      matTooltip="Service Eudémis"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.ICARE_ADMIN] && widgetsLocalVisibility?.[WidgetType.ICARE_ADMIN]"
      src="assets/widgets/icare/eudemis.png"
      (click)="togglePanel('widgetIcareAdmin')"
      matTooltip="Service Eudémis Admin"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.OPTI_SEMIS] && widgetsLocalVisibility?.[WidgetType.OPTI_SEMIS]"
      src="assets/widgets/optiSemis/optiSemis.png"
      (click)="togglePanel('widgetOptiSemis')"
      matTooltip="OptiSemis"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.REVOLT] && widgetsLocalVisibility?.[WidgetType.REVOLT]"
      src="assets/images/revolt/register/revolt_logo_small.png"
      (click)="togglePanel('widgetRevolt')"
      matTooltip="Rêvolt"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="widgetsVisibility?.[WidgetType.INTERRA_SCAN] && widgetsLocalVisibility?.[WidgetType.INTERRA_SCAN]"
      src="assets/widgets/interraScan/InterraScan_logo.png"
      (click)="togglePanel('widgetInterraScan')"
      matTooltip="InterraScan"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      class="qualih2o-tab"
      *ngIf="widgetsVisibility?.[WidgetType.QUALIH2O] && widgetsLocalVisibility?.[WidgetType.QUALIH2O]"
      src="assets/widgets/qualih2o/QualiH2O_icon.png"
      (click)="togglePanel('widgetQualiH2O')"
      matTooltip="Quali'H2O"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      icon="fact_check"
      *ngIf="layerToValid && canUserValidateLayer"
      (click)="togglePanel('editionValidation')"
      matTooltip="Validation"
      matTooltipPosition="before"
      i18n-matTooltip
    />
    <lib-ol-gmap-button
      *ngIf="visibleWidgets.length"
      icon="keyboard_control"
      [matMenuTriggerFor]="widgetHideMenu"
      matTooltip="Fonctionnalités"
      matTooltipPosition="before"
      i18n-matTooltip
    />
  </lib-ol-gmap-toolbar>
</lib-ol-gmap>
<div class="widget-toolbar-left">
  <smv-calendar *ngIf="widgetsVisibility?.[WidgetType.DATEPICKER] && widgetsLocalVisibility?.[WidgetType.DATEPICKER]" />
  <smv-search-address *ngIf="widgetsVisibility?.[WidgetType.SEARCH] && widgetsLocalVisibility?.[WidgetType.SEARCH]" />
  <smv-coordinates-zoom
    *ngIf="application && widgetsVisibility?.[WidgetType.COORDINATES_ZOOM]"
    [hidden]="!widgetsLocalVisibility?.[WidgetType.COORDINATES_ZOOM]"
  />
</div>
<smv-timeline
  *ngIf="application && widgetsVisibility?.[WidgetType.TIMELINE]"
  [appId]="application.id"
  [hidden]="!widgetsLocalVisibility?.[WidgetType.TIMELINE]"
/>
<smv-vigie-virose
  *ngIf="application && widgetsVisibility?.[WidgetType.VIGIEVIROSE]"
  [applicationId]="application.id"
  [panelHide]="!sidePanels.widgetVigieVirose"
  (displayed)="togglePanel('widgetVigieVirose')"
/>
<smv-vigie-virose-admin
  *ngIf="application && widgetsVisibility?.[WidgetType.VIGIEVIROSE_ADMIN]"
  [applicationId]="application.id"
  [panelHide]="!sidePanels.widgetVigieViroseAdmin"
  [canImportDatum]="isUserApplicationAdmin"
  [canEditTrap]="canUserEditLayer"
  (displayed)="togglePanel('widgetVigieViroseAdmin')"
/>
<smv-icare
  *ngIf="application && widgetsVisibility?.[WidgetType.ICARE]"
  [applicationId]="application.id"
  [canImport]="isUserApplicationAdmin"
  [panelHide]="!sidePanels.widgetIcare"
  (displayed)="togglePanel('widgetIcare')"
/>
<smv-icare-admin
  *ngIf="application && widgetsVisibility?.[WidgetType.ICARE_ADMIN]"
  [applicationId]="application.id"
  [canImport]="isUserApplicationAdmin"
  [panelHide]="!sidePanels.widgetIcareAdmin"
  (displayed)="togglePanel('widgetIcareAdmin')"
/>
<smv-quali-h2o
  *ngIf="widgetsVisibility?.[WidgetType.QUALIH2O]"
  [panelHidden]="!sidePanels.widgetQualiH2O"
  (closePanel)="togglePanel('widgetQualiH2O')"
/>
<smv-opti-semis
  *ngIf="application && widgetsVisibility?.[WidgetType.OPTI_SEMIS]"
  [applicationId]="application.id"
  [canImport]="isUserApplicationAdmin"
  [panelHide]="!sidePanels.widgetOptiSemis"
  (displayed)="togglePanel('widgetOptiSemis')"
/>
<smv-revolt
  *ngIf="application && widgetsVisibility?.[WidgetType.REVOLT]"
  [applicationId]="application.id"
  [panelHide]="!sidePanels.widgetRevolt"
  (displayed)="togglePanel('widgetRevolt')"
/>
<smv-interra-scan
  *ngIf="application && widgetsVisibility?.[WidgetType.INTERRA_SCAN]"
  [applicationId]="application.id"
  [canEdit]="canUserEditLayer"
  [panelHide]="!sidePanels.widgetInterraScan"
  (displayed)="togglePanel('widgetInterraScan')"
/>

<smv-edition-validation
  *ngIf="layerToValid && canUserValidateLayer"
  [layer]="layerToValid"
  [panelHide]="!sidePanels.editionValidation"
  (displayed)="togglePanel('editionValidation')"
/>

<div class="overlay" *ngIf="sidePanels.dataLayerEditor || displayOverlay"></div>

<ng-container *ngIf="application">
  <smv-application-settings
    *ngIf="isUserApplicationAdmin && sidePanels.settings && configuration && layerGroups"
    [application]="application"
    [configuration]="configuration"
    [layerGroups]="layerGroups"
  />

  <mat-card class="side-panel" *ngIf="sidePanels.dataLayers">
    <mat-card-title>
      <div>
        <span i18n="Application panel|Title">Couches de données</span>
        <button mat-icon-button (click)="togglePanel('dataLayers')">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <smv-data-layers
        [layerGroups]="layerGroups"
        [canUserToggleEditionMode]="canUserModifyLayer"
        [canUserEditLayer]="isUserApplicationAdmin"
        (toggleSatelliteView)="olgmap.toggleSatelliteView($event)"
        (viewLayerProperties)="displayLayerProperties($event)"
        (toggleEditionMode)="updateLayerEditionMode($event)"
      />
    </mat-card-content>
    <mat-card-actions class="layer-editor-actions" *ngIf="isUserApplicationAdmin">
      <button mat-flat-button color="primary" (click)="saveDisplay()" [ngClass]="{ btnSpinner: isSavingDisplay }">
        <mat-icon>save</mat-icon>
        <ng-container i18n="Button|Layer manager action">Enregistrer l'affichage</ng-container>
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="togglePanel('dataLayerEditor')"
        i18n="Button|Layer manager action"
      >
        Ajouter ou modifier des couches
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card cdkScrollable class="side-panel" *ngIf="sidePanels.layerPropertyViewer">
    <mat-card-title>
      <div>
        <span i18n="Application panel|Title">Propriétés de la couche</span>
        <button mat-icon-button (click)="togglePanel('layerPropertyViewer')">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <smv-layer-properties [layer]="activeLayer" (closePanel)="togglePanel('dataLayers')" [readonly]="true" />
    </mat-card-content>
  </mat-card>

  <smv-data-layer-edition-panel
    *ngIf="sidePanels.dataLayerEditor"
    [layerGroups]="layerGroups"
    [(application)]="application"
    (layersUpdate)="updateLayers($event)"
    (closePanel)="togglePanel('dataLayers')"
  />

  <mat-card class="side-panel" *ngIf="sidePanels.featureDetails">
    <mat-card-title>
      <div>
        <span i18n="Application panel|Title">Informations</span>
        <button mat-icon-button (click)="togglePanel('featureDetails')">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <smv-feature-details [canEdit]="canUserModifyLayer" />
    </mat-card-content>
  </mat-card>

  <mat-card class="side-panel" *ngIf="sidePanels.legend">
    <mat-card-title>
      <div>
        <span i18n="Application panel|Title">Légende</span>
        <button mat-icon-button (click)="togglePanel('legend')">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <smv-legend></smv-legend>
    </mat-card-content>
  </mat-card>
</ng-container>

<mat-menu #widgetHideMenu>
  <ng-container *ngFor="let widget of visibleWidgets">
    <button mat-menu-item (click)="$event.stopPropagation(); toggleWidgetLocalVisibility(widget.widgetId)">
      <mat-icon *ngIf="widgetsLocalVisibility?.[widget.widgetId]">visibility_on</mat-icon>
      <mat-icon *ngIf="!widgetsLocalVisibility?.[widget.widgetId]">visibility_off</mat-icon>
      {{ widget.label }}
    </button>
  </ng-container>
</mat-menu>
